// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import ListPosts from 'components/blog/listPosts';
import { device } from 'components/device';
import Accordion from 'components/Accordion';
import SearchDoctor from 'components/SearchDoctor';
import { createDoctorData } from 'utils/createDoctorData';

import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';

import { HeaderContext } from 'context/header';

// Context
import { DoctorContext } from 'context/doctor';

// Styles
export const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    display: ${props => props.isDesktop && 'flex'};
    padding: 0 20px;
  }

  .cardsCdi {
    section {
      width: 100%;
      div {
        width: 100%;
        margin-left: 0;
        @media ${device.laptop} {
          display: flex;
        }
        div {
          > ul {
            width: 100%;
            @media ${device.laptop} {
              display: flex;
              flex-direction: column;
              margin: 0;
            }
            > li {
              width: 100%;
              margin-right: 0;
              > h2 {
                font-weight: 400;
                width: 65%;
                padding-bottom: 1.875rem;

                @media ${device.laptop} {
                  width: 100%;
                  margin: 0;
                  padding: 0;
                  padding-bottom: 1.875rem;
                }
              }
              :hover {
                box-shadow: unset;
              }
              :first-child {
                margin-right: 0;
                padding: 0 15px 0 0 !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                  border-bottom: 1px solid #c6c8cc;
                  border-radius: unset;
                }
              }
              :last-child {
                margin-right: 0;
                padding: 0 0 0 15px !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                }
              }
              @media ${device.laptop} {
                height: unset;
              }
              ul {
                grid-template-columns: 1fr;
                width: 100%;
                li {
                  margin-right: 0;
                  @media ${device.laptop} {
                    width: 100%;
                    height: unset;
                  }
                  p a {
                    display: contents;
                  }
                  h3 {
                    width: 100%;
                    > a {
                      :first-child {
                        @media ${device.laptop} {
                          display: flex;
                          width: 100%;
                        }
                        @media ${device.tablet} {
                          width: 100%;
                        }
                      }
                      :last-child {
                        @media ${device.laptop} {
                          top: 0;
                          right: 0;
                        }
                      }
                    }
                    @media ${device.laptop} {
                      width: 100%;
                      position: relative;
                    }
                  }
                }
                @media ${device.laptop} {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

export const AccordionContainer = styled.div`
  margin-bottom: 4.375rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }
`;

export const AccordionContainerTitle = styled(ReactMarkdown)`
  p,
  h2 {
    font-size: 2.25rem;
    font-weight: 600;
    text-align: center;
    margin: 4.375rem 0;

    @media ${device.laptop} {
      font-size: 1.625rem;
      margin: 1.875rem 0;
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const RenderPage = (
  page,
  isDesktop,
  pageContext,
  doctorsList,
  doctors,
  location
) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckqgoft8w0iyr0c75r569mkng', true)}
            fragments={getMarkdown(page, 'ckqgoft8w0iyr0c75r569mkng')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isOncologia
            isBorder
            withoutBorderTop
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
          <AccordionContainer>
            <AccordionContainerTitle
              children={
                getMarkdown(page, 'ckqgokc200np30a67gp9na9wh')[0]
                  .singletexts[0] || ''
              }
            />
            <div>
              <Accordion
                markdownContent={getMarkdown(
                  page,
                  'ckqgokc200np30a67gp9na9wh',
                  true
                )}
              />
              <Accordion
                markdownContent={getMarkdown(
                  page,
                  'ckqgonkkg0ool0a675somgjzd',
                  true
                )}
              />
              <Accordion
                markdownContent={getMarkdown(
                  page,
                  'ckqgopaao0ptb0c75ywq44ur9',
                  true
                )}
              />
              <Accordion
                markdownContent={getMarkdown(
                  page,
                  'ckqgoqia00rct0c69gzxu0qd4',
                  true
                )}
              />
              <Accordion
                markdownContent={getMarkdown(
                  page,
                  'ckqgos3dk0ui00b2792q9e9j9',
                  true
                )}
              />
              <Accordion
                markdownContent={getMarkdown(
                  page,
                  'ckqgotiaw0up60b27yr1xb0ze',
                  true
                )}
              />
              <Accordion
                markdownContent={getMarkdown(
                  page,
                  'ckqgphd3k17wh0a670a2z2utc',
                  true
                )}
              />
            </div>
          </AccordionContainer>
        </Part>
        <Part gridColumn={'2 / -2'} notPadding notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrf4bbc01xg90a71ow4w7m7j', true)}
            fragments={getMarkdown(page, 'ckrf4bbc01xg90a71ow4w7m7j')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isOncologia
            withoutBorderTop
          />
        </Part>

        <Part gridColumn={'1 / -1'}>
          <SearchDoctor location={location} noSearch displayTags="none" />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrf4g9ko23s70b72lt2wdump', true)}
            fragments={getMarkdown(page, 'ckrf4g9ko23s70b72lt2wdump')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isOncologia
            isBorder
            withoutBorderTop
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrfdt6fk4xea0d80v4ar4dl0', true)}
            fragments={getMarkdown(page, 'ckrfdt6fk4xea0d80v4ar4dl0')}
            padding="4.375rem 0 3.125rem"
            marginListDesk="0"
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            infusion
            isOncologia
            contentMarginBottom="0"
            titleTagH2WidthDesk="15rem"
            contentTagUlListStyleType="none"
            contentTagLiImgMargin="0 0.625rem 0 0"
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrfe2hb453d00b22o42zie87', true)}
            fragments={getMarkdown(page, 'ckrfe2hb453d00b22o42zie87')}
            padding="1.875rem 0"
            notTitleBold
            isBottom
            prontoAtendimento
            isFacilities
            isOncologia
            bordeTopWidth="36.5rem"
            imgPaddingRight
            contentTagEmColor="#231F20"
            containerPLastChildMarginBottom="unset"
            contentPWhiteSpaceMobile="unset"
            contentContactCardChild3
            contentContactCardChild6
            contentContactCardChild3MaxWidthMobile="17.5rem"
            contentContactCardChild6MaxWidthMobile="13.75rem"
            boxContentPaddingBottomLaptop="0"
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckumptakop2480c73l5q0j57u', true)}
            fragments={getMarkdown(page, 'ckumptakop2480c73l5q0j57u')}
            padding="1.875rem 0 4.375rem"
            notTitleBold
            isBottom
            prontoAtendimento
            isFacilities
            isOncologia
            bordeTopWidth="36.5rem"
            imgPaddingRight
            contentTagEmColor="#231F20"
            containerPLastChildMarginBottom="unset"
            contentPWhiteSpaceMobile="unset"
            contentContactCardChild3
            contentContactCardChild3MaxWidthMobile="13.75rem"
            boxContentPaddingBottomLaptop="0"
          />
        </Part>
        {page?.[0]?.posts?.length > 0 && (
          <Part gridColumn={'2 / -2'} padding borderTop>
            <ListPosts
              title="Blog do Sírio-Libanês"
              postsToShow={3}
              posts={page?.[0]?.posts}
              headerLink={`/${page[0]?.customURLBlog ?? 'blog/'}`}
            />
          </Part>
        )}
      </GridContainer>
    </>
  );
};

const Radioterapia = ({
  page,
  pageContext,
  doctors,
  specialtyDoctors,
  apiDoctors,
  location,
}) => {
  const [isDesktop, setDesktop] = useState(true);
  const [, setRedirect] = useState(false);
  const { doctorsList, setDoctorsList, setDoctorsOncology } = useContext(
    DoctorContext
  );

  const formattedDoctors = specialtyDoctors
    .map(doctor =>
      createDoctorData({
        doctorCMS: doctor,
        doctorsAPI: apiDoctors,
      })
    )
    ?.filter(e => e)
    .sort(
      (a, b) =>
        (a.name.trim().toLowerCase() > b.name.trim().toLowerCase() && 1) || -1
    )
    .sort((a, b) => b.employee - a.employee);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    setDoctorsOncology(formattedDoctors);
    setDoctorsList(formattedDoctors);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
    setRedirect(true);
  });

  const getImage = getMarkdown(page, 'ckrdlduagd37g0b27fvfvveu6', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {RenderPage(page, isDesktop, pageContext, doctorsList, doctors, location)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          doctorsApi {
            doctors {
              id
              name
              gender
              crm
              email
              phone
              phonecommercial
              specialty
              employee
              estab
              state
            }
          }
          gcms {
            doctors(first: 1000) {
              active
              id
              id_api
            }
            specialty(
              locales: [pt_BR, en]
              where: { id: "ckx7s6csg56uv0c258uvmatvj" }
            ) {
              doctor(locales: [pt_BR, en], first: 1000) {
                id
                id_api
                active
                assignment
                name
                crm
                photo {
                  handle
                  url
                  height
                  width
                }
                phonecommercial
                state
                employee
                specialties {
                  id
                  name
                }
              }
            }
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckrfe5b5s55640d80hw63jy1i" }) {
                featuredMarketing(locales: [en, es, pt_BR]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeHighlight
                  highlight
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <Radioterapia
            page={response.gcms.site.pages}
            doctors={response.gcms.doctors}
            specialtyDoctors={response.gcms.specialty.doctor}
            apiDoctors={response.doctorsApi.doctors}
            location={props.location}
            {...props}
          />
        );
      }}
    />
  );
};
